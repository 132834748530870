import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export default {
  async getCompanyWareHouse() {
    return await axios
      .get("company-warehouse")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getCompanyWareHouseByVendor(vendor) {
    return await axios
      .get("company-warehouse/by-vendor/" + vendor)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getInventory(company_warehouse_id) {
    return await axios
      .get("inventory/" + company_warehouse_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getInventoryForDraftSelectMore(obj) {
    return await axios
      .get(
        `inventory/get-Inventory-For-Draft-Select-More?company_warehouse_id=${obj.company_warehouse_id}&draft_master_id=${obj.draft_master_id}`
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async viewDraft(draft_id) {
    return await axios
      .get("purchase-internal-transfer/view-draft/" + draft_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async removeDraft(draft_id) {
    return await axios
      .delete("purchase-internal-transfer/remove-draft/" + draft_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async removeAllDraft(master_id) {
    return await axios
      .delete("purchase-internal-transfer/remove-all-draft/" + master_id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  // async deleteDraft(id) {
  //   return await axios
  //     .delete("inventory/draft/" + id)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       throw new Error(err.response.data.message);
  //     });
  // },
  // async getDraft(company_warehouse_id) {
  //   return await axios
  //     .get("inventory/draft/" + company_warehouse_id)
  //     .then((res) => {
  //       return res.data;
  //     })
  //     .catch((err) => {
  //       throw new Error(err.response.data.message);
  //     });
  // },
  async saveGst(gst) {
    return await axios
      .post("/purchase-internal-transfer/gst/update/gst", gst)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getGst() {
    return await axios
      .get(`/purchase-internal-transfer/gst/view`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getOwnVendors() {
    let type = "OWN";
    return await axios
      .get("vendors/vendor/by/type/" + type)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveData(req) {
    return await axios
      .post("purchase-internal-transfer", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async saveAsDraft(req) {
    return await axios
      .post("purchase-internal-transfer/internal-tansfer-Draft", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async updateDraft(req) {
    return await axios
      .patch("purchase-internal-transfer/internal-tansfer-Draft-Update", req)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getRefNo() {
    return uuidv4();
    // return await axios
    //   .get(`purchase-internal-transfer/transfer/code`)
    //   .then((res) => {
    //     return res.data;
    //   })
    //   .catch((err) => {
    //     throw new Error(err.response.data.message);
    //   });
  },
};
