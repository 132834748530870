<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-container class="px-0">
            <v-row class="mt-8">
              <v-col cols="12" md="3">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>Company
                  Warehouse</label
                >
                <v-autocomplete
                  v-model="compnay_warehouse"
                  :items="compnay_warehouses"
                  item-text="name"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Vendor"
                  :rules="[(v) => !!v || 'Vendor is Required']"
                  outlined
                  single-line
                  height="48"
                  @change="getPo"
                >
                </v-autocomplete> </v-col
              ><v-col cols="12" md="3">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>Po
                  Number</label
                >
                <v-autocomplete
                  v-model="poNumber"
                  :items="poNumbers"
                  item-text="po_number"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mt-5
                    mb-0
                  "
                  placeholder="Select a PO Number"
                  :rules="[(v) => !!v || 'PO number is Required']"
                  outlined
                  single-line
                  height="48"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex justify-end mx-5">
                <v-btn
                  @click="clearfilter"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-secondary
                    bg-light
                    py-3
                    px-6
                    mr-2
                  "
                  >Clear</v-btn
                >

                <v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  @click="search"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- ## Dispatch Master -->
      <v-card class="card-shadow border-radius-xl mt-5" v-show="showMaster">
        <v-card-text class="card-padding">
          <v-data-table
            :headers="dispatchMasterHeader"
            :items="dispatchMaster"
            class="table"
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }"
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.recieved_date`]="{ item }">
              {{ item.recieved_date.split("T")[0] }}</template
            >

            <template v-slot:[`item.action1`]="{ item }">
              <v-btn
                @click="viewDetails(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class=""
                color="green"
                >View
              </v-btn></template
            >

            <template v-slot:[`item.action2`]="{ item }">
              <v-btn
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class=""
                color="green"
                @click="excelImport(item.child)"
                >Export as excel
              </v-btn></template
            >
          </v-data-table>
        </v-card-text>
      </v-card>
      <!-- ## Dispatch child -->
      <v-dialog v-model="dialog" persistent max-width="1100">
        <v-card class="card-shadow border-radius-xl">
          <v-card-text class="card-padding">
            <v-data-table
              :headers="dispatchChildHeader"
              :items="dispatchchild"
              class="table"
              hide-default-footer
              mobile-breakpoint="0"
            >
              <template v-slot:top class="mt-5">
                <v-toolbar flat>
                  <v-toolbar-title>Dispatch List (Recieved)</v-toolbar-title>

                  <v-spacer></v-spacer>
                  <v-btn
                    @click="excelImport(dispatchchild)"
                    icon
                    elevation="0"
                    :ripple="false"
                    color="green"
                    >Export
                  </v-btn>

                  <v-btn
                    @click="closeDialogue"
                    icon
                    elevation="0"
                    :ripple="false"
                    class="ml-5"
                    color="red"
                    >close
                  </v-btn>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import dispatchapi from "../../internal-dispatch/api";
import apiPo from "../../../dispatch/delivery-notes/api";
export default {
  created() {
    this.initialize();
  },
  data() {
    return {
      type: "INTERNAL TRANSFER",
      compnay_warehouse: null,
      compnay_warehouses: [],
      poNumber: null,
      poNumbers: [],
      overlay: false,
      dialog: false,
      // Data Table
      dispatchMaster: [],
      showMaster: false,
      dispatchchild: [],
      dispatchChildHeader: [
        {
          text: "Batch No:",
          value: "batch_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Item Name",
          value: "item_name",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Lot No:",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "No: of Package(Ordered) ",
          value: "ordered_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "No: of Package(Recieved)",
          value: "received_no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Quantity (Ordered)",
          value: "ordered_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Quantity (Recieved)",
          value: "received_quantity",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
      ],
      dispatchMasterHeader: [
        {
          text: "Deleivered On",
          value: "recieved_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "PO No:",
          value: "po_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "Vehicle No:",
          value: "vehicle_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "action1",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
        {
          text: "",
          value: "action2",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          sortable: false,
        },
      ],
      headers: [],
    };
  },
  methods: {
    async initialize() {
      this.overlay = true;
      this.compnay_warehouses = await api.getCompanyWareHouse();
      this.overlay = false;
    },
    async getPo() {
      try {
        this.poNumbers = await dispatchapi.getPo(this.compnay_warehouse.id);
      } catch (error) {}
    },
    clearfilter() {},
    async search() {
      try {
        this.overlay = true;
        this.dispatchMaster = await apiPo.getConfirmedDispatchByPO(
          this.poNumber.po_number
        );

        this.showMaster = true;
        this.overlay = false;

        // console.log("this.result", this.DispatchMaster);
      } catch (error) {
        console.log(error);
      }
    },
    viewDetails(item) {
      this.dialog = true;
      this.dispatchchild = item.child;
      console.log("this.dispatchchild", this.dispatchchild);
    },
    async excelImport(item) {
      let param = [];
      item.forEach((el) => {
        let data = {
          item_name: el.item_name,
          batch_no: el.batch_no,
        };
        param.push(data);
      });
      await apiPo.downloadExcel(param);
    },
    closeDialogue() {
      this.dialog = false;
      this.dispatchchild = [];
    },
  },
};
</script>

<style></style>
