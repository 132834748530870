import axios from "axios";

export default {
  async getVendorsByType(type) {
    return await axios
      .get("vendors/vendor/by/type/" + type)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getVendors() {
    return await axios
      .get("vendors")
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getPo(req) {
    return await axios
      .get(`purchase-auction/get-po/by-vendor?vendor_id=${req.vendor}&purchase_type=${req.type}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getConfirmedDispatchByPO(po_number) {
    return await axios
      .get(`dispatch/confirm/list/by-po/${encodeURIComponent(po_number)}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getConfirmedDispatchByPOs(po_number) {
    return await axios
      .get(`dispatch/confirm/list/by-pos/`, { params: { po_number } })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getRecievedDispatchCode(season_sales) {
    return await axios
      .get(`dispatch/dispatch-code/recieved`, { params: { season_sales } })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getRecievedDispatch(id) {
    return await axios
      .get(`dispatch/recieved/list/by-id/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getChildByMaster(id) {
    return await axios
      .get(`dispatch/child-by-master/${id}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },
  async getRecievedDispatchMasterBySeasonAndAuctionCenter(auction_center, season) {
    return await axios
      .get(`dispatch/dispatch-recieved-master-by-auctioncenter-season?auction_center=${auction_center}&season=${season}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async getConfirmedDispatchBySeasonSale(season_sale) {
    return await axios
      .get(`dispatch/confirm/list/season/sale/` + season_sale)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(err.response.data.message);
      });
  },

  async downloadExcel(child) {
    return await axios
      .post(
        `dispatch/confirm/list/download`,
        { child: child },
        {
          responseType: "blob",
        }
      )
      .then((res) => {
        const url = URL.createObjectURL(
          new Blob([res.data], {
            type: "application/vnd.ms-excel",
          })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "recieved-items.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
    // )
    // .then((res) => {
    //   return res.data;
    // })
    // .catch((err) => {
    //   throw new Error(err.response.data.message);
    // });
  },
};
